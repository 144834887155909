import {
  imageonerm,
  imagetworm,
  imagethreerm,
  imagefourrm,
  imagefiverm,
  imagesixrm,
  imagesevenrm,
  imageeightrm,
  imageninerm,
  imagetenrm,
} from "../../images/RuiruMwmbley";

import {
  imageonermsec,
  imagetwormsec,
  imagethreermsec,
  imagefourrmsec,
  imagefivermsec,
  imagesixrmsec,
  imagesevenrmsec,
  imageeightrmsec,
  imageninermsec,
  imagetenrmsec,
} from "../../images/RuiruMatangi";

import {
  imageonermk,
  imagetwormk,
  imagethreermk,
  imagefourrmk,
  imagefivermk,
  imagesixrmk,
  imagesevenrmk,
  imageeightrmk,
  imageninermk,
  imagetenrmk,
} from "../../images/RuiruMatangiKimbo";
import {
  imageonerN,
  imagetworbN,
  imagethreerN,
  imagefourrN,
  imagefiverN,
  imagesixrN,
  imagesevenrN,
  imageeightrN,
  imageninerN,
  imagetenrN,
} from "../../images/House11";

// LATEST 16

export const data = [
  {
    id: 11,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/jujamastore4.jpg?alt=media&token=1000510e-5f5d-4249-a7a6-2d5019da64c9",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/jujamastore6.jpg?alt=media&token=4f63b783-7e42-4f3d-8332-aa88f03b10db",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/jujamastore5.jpg?alt=media&token=f2ae9dc4-6e5b-424f-8670-9e7f5d0f1954",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/jujamastore7.jpg?alt=media&token=fe75ea54-1a01-43f8-8f0e-9f8248b2b1ad",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/jujamastore1.jpg?alt=media&token=c5e1f965-d50a-40a6-aff8-3bf767b2b75a",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/jujamastore2.jpg?alt=media&token=10b5d9a8-885b-44c4-82a4-40b77b4c3afb",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/jujamastore3.jpg?alt=media&token=614cfd6c-d522-45df-8248-009625aed084",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/jujamastore8.jpg?alt=media&token=3478105b-f829-435e-8d98-c0dc10af4935",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/jujamastore9.jpg?alt=media&token=47d1be52-1673-445f-a20f-b900d2320bca",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/jujamastore10.jpg?alt=media&token=7d28563a-b81b-48da-882b-f0d0b4e7bb5a",
    ],
    description:
      "Affordable 3 bedroom house in Juja, Kenya, 10 million neg, Newly built, master ensuite",
    location: "Juja Mastore",
    price: "10M",
    parking: "3 Cars",
    security: "Security with guards 24hrs",
    water: "Water flow supply is sufficient ",
    more: `10M neg, Newly built, 3 bedroom modern master ensuite,  In Juja Mastore, 4km from Thika rd, Along newly built Juja tarmac,40 by 120 plot
    Ready title, 
    Water supply is sufficient, 
    Electricity connected,
    Internet services available,
    Parking is massive, 
    Sitting room is spacious, 
    Spacious dining room table, 
    Open plan spacious kitchen fitted with wall to wall cabinets,
    Unique  finishes, 
    Gypsum ceiling, 
    Chadaria bulbs, 
    Superb painting and effects,
    Pantry room, 
    Laundry area,
    Common separate toilet and bathroom,
    All bedrooms are fitted with wall to wall wardrobes, 
    Spacious Master bedroom is ensuite fitted with wall to wall wardrobes, 
    Hot shower connected, 
    Perimeter wall, 
    Security alarm system, 
    Alarm door bell, 
    The house is close vicinity to Juja shopping mall,Juja Preparatory school and worship places,
    ASKING  PRICE 10M neg.`,
  },
  {
    id: 12,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruKimbo45M1.jpg?alt=media&token=a9848fab-5d84-4435-911a-4c18df59a323",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruKimbo45M2.jpg?alt=media&token=97c87521-34f0-4a8d-8ec8-b38d50b1e606",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruKimbo45M3.jpg?alt=media&token=04184269-b170-432c-91da-73cef4f0a16a",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruKimbo45M4.jpg?alt=media&token=3dcfb256-fcd1-48cf-be56-3fcc205ea1b6",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruKimbo45M5.jpg?alt=media&token=73907c60-b565-4c55-b468-5035727f4088",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruKimbo45M6.jpg?alt=media&token=567527cc-5e3c-4193-939c-56ce3d509cf5",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruKimbo45M7.jpg?alt=media&token=89c0ecd8-2857-41fa-833f-49377187e2eb",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruKimbo45M8.jpg?alt=media&token=c6d8ef8b-96f6-482a-8cd7-1f91405871ae",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruKimbo45M9.jpg?alt=media&token=4760d207-5fb0-4873-b8df-3af26271b8e6",
    ],
    description:
      "HOUSE FOR SALE, 45M, Newly built, 4 bedroom master ensuite with an SQ",
    location: "Ruiru Kimbo",
    price: "45M",
    parking: "3 Cars",
    security: "Security with guards 24hrs",
    water: "Water flow supply is sufficient ",
    more: `1/4acre,
    Ready title, 
    Water supply is sufficient, 
    Security with guards, 
    Parking is massive, 
    Lounge is spacious, 
    Dinning room table, 
    Unique finishes, 
    Gypsum ceiling, 
    Sunken wooden floor, 
    Wall to wall kitchen cabinets, 
    Open kitchen, 
    Pantry room, 
    Study room, 
    Laundry area, 
    Cloakroom for the guest, 
    Wall to wall wardrobes,  
    Master bedroom is ensuite fitted with wall to wall wardrobes, 
    Master bathroom with jacuzzi, 
    Balcony area is large and extensive, 
    Perimeter wall with electric fence, 
    Compound is concrete and with cabro,
    Its close proximity to shopping malls,schools and worship places, 
    ASKING PRICE 45M`,
  },
  {
    id: 13,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage1.jpg?alt=media&token=ec173d3e-357e-468b-8f76-4d8ece31ef23",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage10.jpg?alt=media&token=12653d42-237d-4930-9874-bf669b43dd4a",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage11.jpg?alt=media&token=f438231e-6bdb-422f-a95d-260e0d69a3f5",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage12.jpg?alt=media&token=54b5745a-c9e4-4b8b-9863-dbc6e44a66b1",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage13.jpg?alt=media&token=adbae095-612a-4c8c-a46d-e79fd3fab187",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage14.jpg?alt=media&token=911e9e3d-dcca-4d61-a255-8d3c936d4069",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage15.jpg?alt=media&token=f0a3d356-e4c0-476a-9a1d-d4995bc2fb36",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage16.jpg?alt=media&token=76953ea5-6d17-49e2-be02-445a148db967",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage2.jpg?alt=media&token=a3f492d0-3526-4ace-9f4c-be92c7a9b8f8",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage3.jpg?alt=media&token=e18d09c6-5c55-4994-a0f8-6badb362b21e",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage4.jpg?alt=media&token=142d38e4-02ff-412d-a9ed-e2eb397b42d0",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage5.jpg?alt=media&token=0065e401-444a-45ae-bd7b-dac8b9bd1e45",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage6.jpg?alt=media&token=d7c24daa-82a6-4db1-9a2b-83fb6715c905",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage7.jpg?alt=media&token=1baf3c91-c0e9-4a97-bff3-072169f6ef7b",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage8.jpg?alt=media&token=5dd69cc6-a6aa-4872-b1ca-8af1f1d941fa",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMugutha8.7M_Morgage9.jpg?alt=media&token=b4c7b072-6f08-4d0b-a341-952385443a60",
    ],
    description: "HOUSE FOR SALE, 8.7M Mortgage acceptable",
    location: "Ruiru Mugutha",
    price: "8.7MM",
    parking: "4 Cars",
    security: "Security with guards 24hrs",
    water: "Water flow supply is sufficient ",
    more: `3 bedroom all ensuite, 
    40/80 plot, 
    Ready title, 
    In Ruiru Mugutha,
    In a gated community,
    3km from Thika Rd, 
    Water supply plenty and sufficient, 
    Electricity connected, 
    Internet services available, 
    Parking of 4 cars, 
    Sitting room is spacious, 
    Spacious dining room table, 
    Open plan spacious kitchen fitted with wall to wall cabinets,
    Unique finishes,
    Superb painting and effects,
    Chandaria bulbs,  
    Laundry area, 
    Common separate toilet and bathroom, 
    All bedrooms are fitted with wall to wall wardrobes, 
    Spacious Master bedroom fitted with wall to wall wardrobes, 
    Hot shower connected, 
    Perimeter wall, 
    Security alarm system, 
    Alarm door bell, 
    Internet Connected,
    Compound of 6 cars,
    Flower garden,
    Compound with cabro,
    The house is close vicinity to Wankan ,Bethlehem, Liz Wanyoike ,Schools ,Spur Shopping mall and worship places,
    SELLING PRICE 8.7M, 
    Mortgage acceptable `,
  },
  {
    id: 14,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M1.jpg?alt=media&token=d31aca48-7dd3-4de7-80b9-4ebe5e543db9",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M2.jpg?alt=media&token=acda910e-7e36-4192-abc9-710f8c26a946",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M3.jpg?alt=media&token=c1c7145f-1370-4e8f-8f85-e1ab6d6de78d",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M4.jpg?alt=media&token=c2697ab1-2cb9-4d0a-a8b0-0c95cba2cce9",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M5.jpg?alt=media&token=cb135342-5842-439f-ad3e-9e95a331980c",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M6.jpg?alt=media&token=a1b0b290-2c10-4c6d-8962-995af6cb322d",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M8.jpg?alt=media&token=f5f660ba-a5b3-424d-b160-23024713797f",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M9.jpg?alt=media&token=29aa326e-e7cc-46a3-a58f-ed16589596e2",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M10.jpg?alt=media&token=1a6e5580-c275-4d41-8cd3-9a7bdaf8f0b1",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M11.jpg?alt=media&token=6cc4add5-77b7-4708-94af-32f832c47808",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M12.jpg?alt=media&token=31604057-0c9d-4a94-b609-1ae3eeea3ae5",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M13.jpg?alt=media&token=af67c5a8-74bc-420e-9ab4-19d4e0b9fb3e",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M14.jpg?alt=media&token=556e6e86-e6d3-4054-aff0-8454fecc359f",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M15.jpg?alt=media&token=69d7ae82-5f4d-46f2-8a7e-4b947e9b6e28",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMuguthaMassionate14M6.jpg?alt=media&token=a1b0b290-2c10-4c6d-8962-995af6cb322d",
    ],
    description: "HOUSE FOR SALE, Affordable Mansionate 14M",
    location: "Ruiru Mugutha",
    price: "14M",
    parking: "3 Cars",
    security: "Security with guards 24hrs",
    water: "Water flow supply is sufficient ",
    more: `Newly Built house, 
    4 bedroom all ensuite, 
    In a gated community, 
    In Ruiru Mugutha,
    3 Km from Thika rd, 
    40 by 80 plot,
    Ready title, 
    Water supply sufficient, 
    Security with guards, 
    Parking is massive,
    With a front paveway flower garden,
    Lounge is spacious, 
    Dinning room table, 
    With unique finishes and design,
    Chandaria bulbs, 
    Gypsum ceiling, 
    Tiled floor, 
    Open plan kitchen fitted with wall to wall cabinets, 
    Pantry room, 
    Laundry area, 
    Common separate toilet and bathroom, 
    Guest room is spacious and fitted with wall to wall closet,
    First floor consists, 
    Friendly staircases, 
    Open spacious window that gives penetrating light, 
    All bedrooms are ensuite and fitted with wall to wall closet,
    With a large extensive balcony, 
    Common separate toilet and bathroom, 
    Master bedroom is ensuite fitted with wall to wall closet, 
    All bathrooms are fitted with hot shower, 
    Alarm door bell, 
    Security alarm, 
    Compound with cabro, 
    Its  close vicinity to Spur shopping mall, Wankan school Bethlehem school and worship places, 
    ASKING PRICE 14M`,
  },
  {
    id: 15,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMatangi7.5M1.jpg?alt=media&token=48a2f000-884f-4174-b844-b04a780b5410",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMatangi7.5M2.jpg?alt=media&token=744e9166-1247-4db6-b56a-6a085af93957",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMatangi7.5M3.jpg?alt=media&token=d4902a90-f4d7-4bc7-beea-ab4c71cbecd0",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMatangi7.5M4.jpg?alt=media&token=d2d80517-f8a7-48ef-9463-0e23595318d1",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMatangi7.5M5.jpg?alt=media&token=384d395b-ca44-48f7-a286-9687f8334b22",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMatangi7.5M6.jpg?alt=media&token=a09cbb69-217d-4d7b-ab60-43d02cd2130d",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMatangi7.5M7.jpg?alt=media&token=fb4dbdac-330c-4b95-a343-89881389b43f",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMatangi7.5M8.jpg?alt=media&token=ef4aa315-f047-495b-9a6d-99e293a043ef",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMatangi7.5M9.jpg?alt=media&token=1406ed7b-7323-495d-a2d4-2f08f671c382",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMatangi7.5M10.jpg?alt=media&token=b7371f0f-2274-466c-b2f8-a92e6aeaab58",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMatangi7.5M11.jpg?alt=media&token=5a4df92f-7423-48a1-9d47-3403fa3947a4",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMatangi7.5M12.jpg?alt=media&token=304f81b0-ca6f-4f49-931b-e8530ac3ddfb",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMatangi7.5M13.jpg?alt=media&token=0777a434-6dd9-494b-8c70-6a8d1e6fe7f9",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/RuiruMatangi7.5M14.jpg?alt=media&token=5a37d0bb-9dc5-4c16-ac27-308df0f8b031",
    ],
    description: "HOUSE FOR SALE 7.5M",
    location: "Ruiru Matangi",
    price: "7.5M",
    parking: "3 Cars",
    security: "Security with guards 24hrs",
    water: "Water flow supply is sufficient ",
    more: `Newly built, 
    3 bedroom all ensuite,
    40/60 plot,
    Ready title, 
    In Ruiru Matangi, Kiambu County, 
    In a gated community,
    3.5 km from Thika Rd, 
    Water supply plenty and sufficient, 
    Electricity connected, 
    Internet services available, 
    Parking of 3 cars, 
    Sitting room is spacious, 
    Spacious dining room table, 
    Open plan spacious kitchen fitted with wall to wall cabinets,
    Unique finishes,
    Superb painting and effects,
    Pantry room, 
    Laundry area, 
    Common separate toilet and bathroom, 
    All bedrooms are fitted with wall to wall wardrobes, 
    Spacious Master bedroom is ensuite fitted with wall to wall wardrobes, 
    Hot shower connected, 
    Perimeter wall, 
    Security alarm system, 
    Alarm door bell, 
    Compound with concrete, 
    The house is close vicinity to Spur shopping  mall, Bethlehem, Wankan,Nibs schools and worshop places, 
    SELLING PRICE 7.5M negotiable`,
  },
  {
    id: 16,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/KarenDagorettiRoad1.jpg?alt=media&token=5f5efa51-a0ed-41b8-8958-c3669edde7cb",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/KarenDagorettiRoad2.jpg?alt=media&token=e32581a8-959b-40df-8766-425f597ca15a",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/KarenDagorettiRoad3.jpg?alt=media&token=f42ae414-e86d-4434-b4a1-61dc5a8a5b2b",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/KarenDagorettiRoad4.jpg?alt=media&token=52fc7b91-a148-40b2-9a7c-2b65da11498d",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/KarenDagorettiRoad5.jpg?alt=media&token=198b3ac3-f090-457c-a19e-aa51fbc3288d",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/KarenDagorettiRoad6.jpg?alt=media&token=2ce49f3c-3feb-4110-9c8c-8d9d33623e80",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/KarenDagorettiRoad7.jpg?alt=media&token=c46e6b46-8d9e-4681-8843-6540f7a8f216",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/KarenDagorettiRoad8.jpg?alt=media&token=c890aedc-1ced-4d6a-a956-cab5bfb2a9fe",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/KarenDagorettiRoad9.jpg?alt=media&token=5d654b00-41e8-4d30-9c88-841f5023beec",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/KarenDagorettiRoad10.jpg?alt=media&token=92887ea7-fe5f-471e-9b75-6b4c28469230",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/KarenDagorettiRoad11.jpg?alt=media&token=48dfc564-0146-46e1-9ab3-e9628da58c24",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/KarenDagorettiRoad12.jpg?alt=media&token=e580bcb1-a34e-45bd-85e1-a208278c63de",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/KarenDagorettiRoad13.jpg?alt=media&token=14310727-b17d-40d6-a0db-686e92eaaa12",
      "https://firebasestorage.googleapis.com/v0/b/pushnotifications-3134c.appspot.com/o/KarenDagorettiRoad14.jpg?alt=media&token=f7abe879-99fe-4761-b1fa-605ca5600037",
    ],
    description:
      "Luxury home Karen, Nairobi Shopping Center 5BR ASTRONOMIC TREMENDOUS GATED HOUSE WITH SWIMMING POOL ONE ACRE LAND ON QUICK SALE",
    location: "Karen Dagoretti Road",
    price: "160M",
    parking: "Available",
    security: "Security with guards 24hrs",
    water: "Water flow supply is sufficient ",
    more: `5 bedroom brand new astronomic tremendous maisonette on quick sale,
    Located at Karen shopping center Plains Nairobi,
    Along Karen Dagoretti road,
    Right next to The Hub shopping Mall,
    Close proxy to Waterfront Mall, Karen Country Club, Karen hospital,
    FEATURES:
    Newly built houses,
    In a gated community on only 3 similar houses,
    Each unit sitting on 1 acre land own compound,
    All en-suite and spacious bedrooms,
    With large widows thus well lit and ventilated,
    With an astronomical beautiful interior finishings & fittings,
    Master with a walk-in closet and a private lounge and an office,
    Capacious lounge with a fireplace,
    Family/TV room, 
    Gym room, 
    Laundry room,
    Spacious kitchen with kitchen island,
    Double car garage,
    Self-contained servant quarters,
    Private swimming pool for each house,
    Well manicured lawns,
    Sitting on 1 acre land each
    In a tight security guaranteed,
    Ready documents available,
    Viewing on at least two days notice appointment booking,
    Selling at Ksh 160M`,
  },

  {
    id: 2,
    images: [
      imageonerm,
      imagetworm,
      imagethreerm,
      imagefourrm,
      imagefiverm,
      imagesixrm,
      imagesevenrm,
      imageeightrm,
      imageninerm,
      imagetenrm,
    ],
    description:
      "3 BEDROOM HOUSE FOR SALE below 7.5 million (goes for 7.5 million negotiable), all bedrooms ensuite ",
    location: "Ruiru Matangi",
    price: "7.5M neg",
    parking: "4 cars",
    security: "Security with guards 24hrs",
    water: "Water flow supply is sufficient ",
    more: `40/60 plot
    Ready title, 
    In Ruiru Matangi, 
    In controlled development community, 
    Water supply plenty and sufficient, 
    Electricity connected, 
    Internet services available, 
    Parking of 4 cars ,
    Sitting room is spacious, 
    Spacious dining room table, 
    Open plan spacious kitchen fitted with wall to wall cabinets,
    Unique finishes,
    Superb painting and effects,
    Chandaria bulbs,  
    Laundry area, 
    Common separate toilet and bathroom, 
    All bedrooms are ensuite and fitted with wall to wall wardrobes, 
    Spacious Master bedroom fitted with wall to wall wardrobes, 
    Hot shower connected, 
    Perimeter wall, 
    Security alarm system, 
    Alarm door bell, 
    Compound with concrete, 
    The house is close vicinity to Wankan ,Bethlehem, Liz Wanyoike ,Schools ,Spur Shopping mall and worship places,
    SELLING PRICE 7.5M Negotiable.`,
  },
  {
    id: 10,
    images: [
      imageonerN,
      imagetworbN,
      imagethreerN,
      imagefourrN,
      imagefiverN,
      imagesixrN,
      imagesevenrN,
      imageeightrN,
      imageninerN,
      imagetenrN,
    ],
    description: "HOUSE FOR SALE 19.5M, Newly built Flatroof ",
    location: "Ruiru Matangi",
    price: "19.5M neg",
    parking: "4 cars",
    security: "Security with guards 24hrs",
    water: "Water flow supply is sufficient ",
    more: `4 bedroom all ensuite 
    Plus SQ,
    In a gated community, 
    In Ruiru Bypass, 
    1Km from Thika rd, 
    40 by 80 plot,
    Ready title, 
    Water supply sufficient, 
    Security with guards, 
    Parking of 4 cars,
    Lounge is spacious, 
    Family room,
    Dinning room table, 
    With unique finishes and design,
    Chandaria bulbs, 
    Gypsum ceiling, 
    Sunken wooden floor, 
    Open plan kitchen fitted with wall to wall cabinets, 
    Pantry room, 
    Laundry area, 
    Common separate toilet and bathroom, 
    Guest room is spacious and fitted with wall to wall closet,
    First floor consists, 
    Friendly staircases, 
    Family room,
    Closed balcony, 
    Open spacious window that gives penetrating light, 
    All bedrooms are fitted with wall to wall closet,
    With a large balcony, 
    Common separate toilet and bathroom, 
    Master bedroom is ensuite fitted with wall to wall closet, 
    All bathrooms are fitted with hot shower, 
    Alarm door bell, 
    Security alarm, 
    Compound with cabro, 
    Its close vicinity to Spur shopping mall, Quickmart shopping mall,schools and worship places, 
    ASKING PRICE 19.5M.`,
  },

  {
    id: 6,
    images: [
      imageonermsec,
      imagetwormsec,
      imagethreermsec,
      imagefourrmsec,
      imagefivermsec,
      imagesixrmsec,
      imagesevenrmsec,
      imageeightrmsec,
      imageninermsec,
      imagetenrmsec,
    ],
    description: "HOUSE FOR SALE 37M NEWLY BUILT HOUSE, 4 bedromms all ensuite",
    location: "In Kenyatta  Rd From Thika Rd its 2km",
    price: "37M",
    security: "Security with guards 24hrs",
    water: "Water flow supply is sufficient ",
    more: `Plus Dsq,
    With a family Room,
    In a gated community, 
    In Kenyatta  Rd,
    From Thika Rd its 2km,
    1/4 acre,
    Ready Title,
    Water supply sufficient, 
    Security with guards 24 hrs,
    Parking is massive,  
    With modern finishes and effects, 
    Lounge is spacious, 
    Dinning room table, 
    With unique finishes and design,
    Sunken wooden floor,
    Open plan kitchen fitted with wall to wall cabinets, 
    Pantry room, 
    Laundry area, 
    Common separate toilet and bathroom, 
    Guest room is spacious and fitted with wall to wall closet,
    First floor consists, 
    Friendly staircases, 
    Open spacious window that gives penetrating light, 
    All bedrooms are ensuite and fitted with wall to wall closet,
    With a large balcony, 
    Common separate toilet and bathroom, 
    Master bedroom is ensuite fitted with a walking inn wall to wall closet, 
    All bathrooms are fitted with hot shower, 
    Alarm door bell, 
    Security alarm,
    Compound with cabro, 
    Well manicured grass and flower garden, 
    Its  close vicinity to shopping mall, school and worship places, 
    SELLING PRICE 37M.`,
  },

  {
    id: 9,
    images: [
      imageonermk,
      imagetwormk,
      imagethreermk,
      imagefourrmk,
      imagefivermk,
      imagesixrmk,
      imagesevenrmk,
      imageeightrmk,
      imageninermk,
      imagetenrmk,
    ],
    description: "HOUSE FOR SALE, 7.5M, Newly built, 3 bedroom master ensuite",
    location: "Ruiru Matangi, 3.5km from Thika rd, Along Kimbo matangi tarmac",
    price: "7.5M",
    parking: "3 Cars",
    security: "Security with guards 24hrs",
    water: "Water flow supply is sufficient ",
    more: "40 by 60 plot, Ready title, Electricity connected,Internet services available, Sitting room is spacious, Spacious dining room table, Open plan spacious kitchen fitted with wall to wall cabinets, Unique  finishes, Gypsum ceiling, Chadaria bulbs, Superb painting and effects, Pantry room, Laundry area, Common separate toilet and bathroom, All bedrooms are fitted with wall to wall wardrobes, Spacious Master bedroom is ensuite fitted with wall to wall wardrobes, Hot shower connected, Perimeter wall, Security alarm system, Alarm door bell, The house is close vicinity to Spur shopping mall, Wankan school,Bethlehem school and worship places, ASKING  PRICE 7.5M",
  },
];
